import actions from "@components/Redux/Global/actions";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const InitBreadCrumbs = (props) => {
   
    const {
        links,
        last
    } = props;

    const {breadcrumbs} = useSelector((state) => state.globalReducer);
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);

	useEffect(() => {
		if(isFirstRender.current){
            isFirstRender.current = false;
        }
        else {
            if((last == breadcrumbs?.last)) return;
            const payload = !links && !last ? [] : {
                links,
                last
            }
            dispatch({
                type: actions.SET_BREADCRUMBS,
                payload: payload
            })
        }
	}, [links, last])
	
    
	return <></>
}

export default InitBreadCrumbs